import React from 'react';
import { Radio } from 'react-radio-group';
import PropTypes from 'prop-types';
import styles from './radio-button.module.scss';

const RadioButton = ({ name, value, label }) => (
  <label className={styles.radioButtonContainer}>
    <Radio name={name} className={styles.radioButton} value={value} />
    {label}
  </label>
);

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
};

export default RadioButton;
