import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../auth-provider';
import { useFormState } from 'react-use-form-state';
import RadioButton from '../radio-button';
import { RadioGroup } from 'react-radio-group';
import Button from '../button';
import TextInput from '../text-input';
import { FormMessagesContext, FormMessages } from '../form';
import styles from './user-settings-form.module.scss';

const UserSettingsForm = () => {
  const {
    currentUser,
    isInit,
    updateUserMetadata,
    refreshCurrentUser
  } = useContext(AuthContext);
  const { setErrorMessage, setStatusMessage } = useContext(FormMessagesContext);
  const [formState, { text }] = useFormState({ ...currentUser });

  const fieldNotEmpty = (field = '') => {
    return field.trim().length > 0;
  };

  useEffect(() => {
    if (currentUser) {
      // Add user to managed form state.
      formState.setField('firstName', currentUser.firstName);
      formState.setField('lastName', currentUser.lastName);
      formState.setField(
        'annotationsVisibility',
        currentUser.annotationsVisibility
      );
    }
  }, [currentUser, formState]);

  if (!isInit) {
    return null;
  }

  if (!currentUser) {
    return <div>You're not allowed to access this page.</div>;
  }

  // The required fields for this form.
  const required = ['firstName', 'lastName', 'annotationsVisibility'];

  const handleChange = (value, property) => {
    formState.setField(property, value);
  };

  const handleSaveUser = async (e) => {
    const { firstName, lastName, annotationsVisibility } = formState.values;
    const allRequiredFieldsFilled = required.every((field) =>
      fieldNotEmpty(formState.values[field])
    );
    e.preventDefault();
    if (!allRequiredFieldsFilled) {
      e.preventDefault(); // Prevent submission if fields are invalid.
      // Set all required fields that are missing values to invalid.
      required.forEach((field) => {
        if (!fieldNotEmpty(formState.values[field])) {
          formState.validity[field] = false;
          formState.setFieldError(formState.values[field], 'This is required');
        }
      }, []);
      return false;
    } else {
      // If all fields are valid, return true and continue with submission.
      const validatedFields = Object.keys(formState.validity);
      validatedFields.forEach((key) => {
        if (!formState.validity[key]) {
          formState.setFieldError(key, `Invalid field for ${key}`);
        }
      });
    }
    try {
      await updateUserMetadata(currentUser.metadataDocId, {
        firstName,
        lastName,
        annotationsVisibility
      });

      setStatusMessage('Your settings have been updated.');
      refreshCurrentUser();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className={styles.userSettingsContainer}>
      <h3 className={styles.userSettingsHeading}>User Settings</h3>
      {formState && (
        <form>
          <div className={styles.formMessages}>
            <FormMessages />
          </div>
          {/* First name */}
          <TextInput
            className={styles.userFormInput}
            id="firstName"
            labelSize="large"
            label="First name"
            maxLength={80}
            onChange={(e) => handleChange(e.target.value, 'firstName')}
            formStateProps={{
              ...text({
                name: 'firstName',
                validateOnChange: true,
                validate: fieldNotEmpty
              })
            }}
            valid={formState.validity['firstName']}
            message={formState.errors['firstName']}
          />
          <TextInput
            className={styles.userFormInput}
            id="last-name"
            labelSize="large"
            inputSize="largeText"
            label="Last name"
            maxLength={80}
            onChange={(e) => handleChange(e.target.value, 'lastName')}
            formStateProps={{
              ...text({
                name: 'lastName',
                validateOnChange: true,
                validate: fieldNotEmpty
              })
            }}
            valid={formState.validity['lastName']}
            message={formState.errors['lastName']}
          />
          {/* Privacy settings */}
          <div className={styles.fieldGroup}>
            <h3
              htmlFor="annotations-visibility"
              className={styles.userSettingsHeading}
            >
              Privacy
            </h3>
            <RadioGroup
              className={styles.radioButton}
              name="annotationsVisibility"
              id="annotations-visibility"
              selectedValue={formState.values.annotationsVisibility}
              // Note - this onchange is from RadioGroup and is takes the chosen value, not an event.
              onChange={(value) => handleChange(value, 'annotationsVisibility')}
            >
              <RadioButton
                value="visible"
                name="annotationsVisibility"
                label="Annotations will be visible to all logged in users"
              />
              <RadioButton
                value="private"
                name="annotationsVisibility"
                label="Annotations can only be seen by New Bagehot Project staff"
              />
            </RadioGroup>
          </div>
          <Button
            className={styles.submitButton}
            color="blue"
            onClick={(e) => handleSaveUser(e)}
            disabled={
              formState.validity['firstName'] === false ||
              formState.validity['lastName'] === false
            }
          >
            Update info
          </Button>
        </form>
      )}
    </div>
  );
};

export default UserSettingsForm;
